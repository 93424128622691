// import Vue from 'vue'
import axios from "axios";
import { Toast } from "vant";

let reqCount = 0,
  resCount = 0; //记录请求次数和响应次数
axios.interceptors.request.use(
  (config) => {
    config.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    if (
      sessionStorage.getItem("token") != "undefined" &&
      config.url.indexOf("rsaKey") != -1
    ) {
      config.headers["X-Access-Token"] = sessionStorage.getItem("token");
    }
    if (config.url.indexOf("sectionUpload") == -1) {
      // 回溯不要加载loading
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
    }

    reqCount++;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    resCount++;
    if (reqCount == resCount) {
      Toast.clear();
    }
    if (!response?.data?.success && response?.data?.message) {
      setTimeout(function () {
        Toast(response.data.message);
      }, 1000);
    }
    return response.data;
  },
  (error) => {
    Toast.clear();
    return Promise.reject(error);
  }
);

//get请求
export const GET = (url, param) => {
  if (param) {
    return axios.get(url, {
      params: param,
    });
  } else {
    return axios.get(url);
  }
};
//post请求
export const POST = (url, param, config) => {
  if (config) {
    return axios.post(url, param, config);
  } else {
    return axios.post(url, param);
  }
};

export const PUT = (url, param) => {
  return axios.put(url, param);
};

export const DELETE = (url) => {
  return axios.delete(url);
};
