import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: (resovle) => require(["@/view/index"], resovle),
    meta: {
      title: "投保",
      isBack: false,
      keepAlive: true,
    },
  },
  {
    path: "/order",
    name: "order",
    component: (resovle) => require(["@/view/order"], resovle),
    meta: {
      title: "我的订单",
      isBack: false,
      // keepAlive: true,
    },
  },
  {
    path: "/detail",
    name: "orderDetail",
    component: (resovle) => require(["@/view/order/detail"], resovle),
    meta: {
      title: "订单详情",
      isBack: false,
      // keepAlive: true,
    },
  },
  {
    path: "/payError",
    name: "payError",
    component: (resovle) => require(["@/view/pay/payError"], resovle),
    meta: {
      title: "支付失败",
      isBack: false,
      // keepAlive: true,
    },
  },
  {
    path: "/paySuccess",
    name: "paySuccess",
    component: (resovle) => require(["@/view/pay/paySuccess"], resovle),
    meta: {
      title: "支付成功",
      isBack: false,
      // keepAlive: true,
    },
  },
  {
    path: "/channelMarker",
    name: "channelMarker",
    component: (resovle) => require(["@/view/channelMarker/index"], resovle),
    meta: {
      title: "立即推广",
      isBack: false,
      // keepAlive: true,
    },
  },
  {
    path: "/promotionLink",
    name: "promotionLink",
    component: (resovle) =>
      require(["@/view/channelMarker/promotionLink"], resovle),
    meta: {
      title: "推广链接",
      isBack: false,
      // keepAlive: true,
    },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
