export function parsingUrl(url) {
  let search =
      url.indexOf("?") != -1 ? url.substring(url.lastIndexOf("?") + 1) : "",
    ret = {},
    seg = search.replace(/^\?/, "").split("&"),
    len = seg.length,
    i = 0,
    s;

  for (; i < len; i++) {
    if (!seg[i]) {
      continue;
    }
    s = seg[i].split("=");
    ret[s[0]] = s[1];
  }

  return ret;
}
