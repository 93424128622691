import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import store from './store';
//清除浏览器默认样式
import "../node_modules/normalize.css/normalize.css";

//自适应rem
import "../node_modules/amfe-flexible/index.js";

// //fastclick
// import FastClick from "fastclick";
// FastClick.attach(document.body);

import "../static/css/reset.css";

// vant全局按需引入
import {
  Lazyload,
  Tab,
  Tabs,
  Field,
  Image as VanImage,
  // Picker,
  // Dialog,
  Popup,
  Toast,
  // RadioGroup,
  // Radio,
  // Overlay,
  Icon,
  Button,
  Checkbox,
  CountDown,
  // List,
  Sticky,
  Loading,
  Overlay,
  SubmitBar,
  Form,
  Empty,
} from "vant";

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(VanImage);
Vue.use(Field);
// Vue.use(Picker);
// Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Lazyload);
// Vue.use(Radio);
// Vue.use(RadioGroup);
// Vue.use(Overlay);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(CountDown);
// Vue.use(List);
Vue.use(Sticky);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(SubmitBar);
Vue.use(Form);
Vue.use(Empty);

// Vue.config.productionTip = false;

//移动端控制台
// import Vconsole from "vconsole";
// const Vconsoles = new Vconsole();
// Vue.prototype.$Vconsole = Vconsoles;

/*路由守卫*/
router.beforeEach((to, from, next) => {
  if (to.name == "index") {
    sessionStorage.removeItem("orderDetail");
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
  // window.scrollTo(0, 0);
});

new Vue({
  render: (h) => h(App),
  router,
  // store
}).$mount("#app");
