import { GET, POST } from "../utils/http";
import config from "../../constans";
const { apiUrl, orderUrl } = config.baseUrl;
let url = `${apiUrl}/insurance/api`,
  order_Url = `${orderUrl}/orders/api`;

// 获取回溯id
export const getRecordId = () => {
  return GET(`${url}/opRecord/getRecordId`);
};

// 分段上传录屏
export const sectionUpload = (params) => {
  return POST(`${url}/opRecord/sectionUpload`, params);
};

//下载pdf
export const download = `${url}/file/getProductFile/`;

//授权登录
export const authLogin = (params) => {
  return POST(`${url}/sichuan/order/authLogin`, params);
};

//微信jsApi支付
export const wXJsApiPay = (params) => {
  return POST(`${url}/pay/wXJsApiPay`, params);
};
// 根据订单号查询回溯id
export const getIdByOrderNo = (params) => {
  return GET(`${url}/opRecord/getIdByOrderNo`, params);
};
//key
export const rsaKey = () => {
  return GET(`${url}/channel/rsaKey?channelId=h5`);
};

//新增订单
export const saveOrder = (params) => {
  return POST(`${order_Url}/sichuan/order/saveOrder`, params);
};

//查询订单列表
export const queryList = (params) => {
  return GET(`${order_Url}/sichuan/order/queryList`, params);
};

//订单详情
export const getOrderByNo = (id) => {
  return GET(`${order_Url}/sichuan/order/getOrderByNo/${id}`);
};

// 查询渠道列表
export const getChannelList = (params) => {
  return GET(`${order_Url}/sichuan/order/getChannelList`, params);
};

// 生成推广二维码
export const savePromotionInfo = (params) => {
  return POST(`${order_Url}/sichuan/order/savePromotionInfo`, params);
};
