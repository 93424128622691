<template>
  <div id="app">
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view></router-view>
    </keep-alive>
    <!--不需要缓存组件-->
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { authLogin } from "./utils/api";
import { parsingUrl } from "./utils/paramsUrl";
export default {
  name: "App",
  data() {
    return {
      appId:
        window.location.href.indexOf("lejian") == -1
          ? "wxe6a79dee228dd08f" // 测试公众号appid
          : "wx295c92a847771800", // 公众号appid
    };
  },
  created() {
    const { pathname } = window.location;
    if (pathname != "/channelMarker" && pathname != "/promotionLink") {
      // 获取授权code
      this.getWxCode();
    }
  },
  mounted() {
    // 隐藏微信分享好友功能
    setTimeout(() => {
      function onBridgeReady() {
        WeixinJSBridge.call("hideOptionMenu");
      }
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
    }, 500);
  },

  methods: {
    // 获取授权code
    async getWxCode() {
      const query = parsingUrl(window.location.href);
      if (!query.code && !sessionStorage.getItem("openId")) {
        // 获取code
        let REDIRECT_URI = encodeURIComponent(window.location.href);
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`;
      } else {
        if (
          !sessionStorage.getItem("userId") ||
          !sessionStorage.getItem("openId")
        ) {
          // 获取userId、openId
          const { code, result } = await authLogin({
            code: query.code,
          });
          if (code == 200) {
            sessionStorage.setItem("token", result.token);
            sessionStorage.setItem("userId", result.id);
            sessionStorage.setItem("openId", result.openId);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
